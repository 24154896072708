import React from "react";
import styled from "@emotion/styled";
import { CUSTOM_BUTTONS } from "@modules/Components/colors";

const Button = styled.button`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  border: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
`;

const ButtonDiv = styled(Button)`
  box-shadow: ${props =>
    props.disableStyle ? "none" : props.buttonType.default.boxShadow};
  background-color: ${props =>
    props.disableStyle
      ? props.disabledBackGroundColor
        ? props.disabledBackGroundColor
        : "#f2f2f3"
      : props.buttonType.default.backgroundColor};
  color: ${props =>
    props.disableStyle && !props.disabledBackGroundColor
      ? "rgba(119, 121, 132, 0.5)"
      : props.buttonType.default.color};
  &:hover {
    box-shadow: ${props =>
      props.disableStyle ? "none" : props.buttonType.hover.boxShadow};
    background-color: ${props =>
      props.disableStyle
        ? props.disabledBackGroundColor
          ? props.disabledBackGroundColor
          : "#f2f2f3"
        : props.buttonType.hover.backgroundColor};
  }
  &:active {
    box-shadow: ${props =>
      props.disableStyle ? "none" : props.buttonType.active.boxShadow};
    background-color: ${props =>
      props.disableStyle
        ? props.disabledBackGroundColor
          ? props.disabledBackGroundColor
          : "#f2f2f3"
        : props.buttonType.active.backgroundColor};
  }
`;

const SearchSpinner = styled.img`
  width: 20px;
  height: 20px;
`;

const SpinnerButton = styled(Button)`
  background-color: #777984 !important;
`;

const CustomSubmitButton = ({
  children,
  isLoading,
  styles,
  action,
  disabled,
  appearDisabled,
  disabledBackGroundColor,
  buttonType,
  className
}) => {
  return isLoading ? (
    <SpinnerButton style={styles} className={className}>
      <SearchSpinner
        src={"https://cdn.cardcash.com/home/images/other/spinner.gif"}
        alt="spinner"
      />
    </SpinnerButton>
  ) : (
    <ButtonDiv
      className={className}
      buttonType={CUSTOM_BUTTONS[buttonType]}
      disabled={disabled}
      disableStyle={disabled || appearDisabled}
      disabledBackGroundColor={disabledBackGroundColor}
      onClick={e => {
        if (action) action(e);
      }}
      style={{
        ...styles,
        cursor: disabled || appearDisabled ? "not-allowed" : "pointer"
      }}
    >
      {children}
    </ButtonDiv>
  );
};

export default CustomSubmitButton;
